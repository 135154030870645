//=================================================
// Logo
//=================================================

/*=============================
// Loading
=============================*/
.loading {
    .logo {
        img {
            width: 7.895rem;
        }
    }
}

/*=============================
// Header
=============================*/
.site-header, .modal-header {
    .logo {
        img {
            width: 8.579rem;
        }
    }
}

/*=============================
// Footer
=============================*/
.site-footer {
    .logo {
        img {
            width: 6.579rem;
        }
    }
}
