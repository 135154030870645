//=================================================
// Variables
//=================================================

//=============================
// Media queries
//=============================
$retina:     "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
$media_xxxl: "(min-width: 1920px)";
$media_xxl:  "(max-width: 1700px)";
$media_xl:   "(max-width: 1199px)";
$media_lg:   "(max-width: 991px)";
$media_md:   "(max-width: 767px)";
$media_sm:   "(max-width: 575px)";

//=============================
// Paths
//=============================
$img:          "../img/";
$fonts:        "../fonts/";
$icons:        "../icons/";

$fa-font-path: "../../fonts/FontAwesome";

//=============================
// Colors
//=============================
$white:                                               #ffffff;
$blue:                                                #0f4c81;
$indigo:                                              #460f81;
$purple:                                              #6f0f81;
$pink:                                                #810f63;
$red:                                                 #810f0f;
$orange:                                              #81390f;
$yellow:                                              #817b0f;
$green:                                               #25810f;
$teal:                                                #0f8166;
$cyan:                                                #0f7a81;

$gray:                                                #eeeeee;
$gray-light:                                          #f8f8f8;
$gray-dark:                                           #828282;

$light:                                               #eeeeee;
$dark:                                                #060606;

// Body
$body_text_primary_color:                             #6c6c6c;
$body_text_secondary_color:                           #060606;
$body_text_tertiary_color:                            #ffffff; // For dark background
$body_headline_color:                                 #060606;
$body_links_color:                                    #060606;
$body_links_hover_color:                              #0f4c81;
$body_background_color:                               #f8f8f8;

$body_main_element_primary_color:                     #0f4c81;
$body_main_element_secondary_color:                   #060606;
$body_border_color:                                   #dbdbdb;
$body_mark_background:                                #fcf8e3;

// Header
$header_color:                                        #6c6c6c;
$header_background_color:                             transparent;
$header_absolute_color:                               #ffffff;
$header_absolute_background_color:                    transparent;
$header_fixed_background_color:                       #ffffff;

// Footer
$footer_color:                                        #ffffff;
$footer_background_color:                             #060606;

// Menu
$menu_links_color:                                    #060606;
$menu_links_hover_color:                              #4febfe;
$menu_links_active_color:                             #0d0f11;

// Menu modal
$menu_modal_links_color:                              rgba(255, 255, 255, 0.5);
$menu_modal_links_hover_color:                        #ffffff;
$menu_modal_links_active_color:                       #ffffff;

// Dropdown
$dropdown_links_color:                                #060606;
$dropdown_links_hover_color:                          #ffffff;
$dropdown_links_active_color:                         #ffffff;
$dropdown_links_background_color:                     #ffffff;
$dropdown_links_hover_background_color:               #060606;
$dropdown_links_active_background_color:              #060606;

// Forms
$input_text_color:                                    #6c6c6c;
$input_border_focus_color:                            #060606;
$input_placeholder_color:                             #6c6c6c;
$label_color:                                         #6c6c6c;

// Form primary
$input_background_primary_color:                      transparent;
$input_border_primary_color:                          #dbdbdb;

// Form secondary
$input_background_secondary_color:                    #ffffff;
$input_border_secondary_color:                        transparent;

// Modals
$modal_content_color:                                 #ffffff;
$modal_background_color:                              #060606;
$modal_background_content_color:                      transparent;

// Card
$card_border_color:                                   #eeeeee;
$card_background_color:                               transparent;

// Widget
$widget_background_color:                             #ffffff;
$widget_border_color:                                 transparent;
$widget_headline_color:                               #060606;

// Embed
$embed_play_background_color:                         #ffffff;
$embed_play_background_hover_color:                   #ffffff;
$embed_play_color:                                    #060606;
$embed_play_hover_color:                              #0f4c81;

// Slider
$slider_button_color:                                 #b9b9b9;
$slider_button_background_color:                      #ffffff;
$slider_button_hover_color:                           #060606;
$slider_button_hover_background_color:                #ffffff;

// Pop-Up
$popup_text_color:                                    #ffffff;
$popup_background_color:                              #060606;
$popup_button_color:                                  #ffffff;
$popup_button_hover_color:                            #060606;
$popup_button_background_color:                       transparent;
$popup_button_background_hover_color:                 #ffffff;

// Back to top
$back_to_top_color:                                   #ffffff;
$back_to_top_background_color:                        #060606;
$back_to_top_hover_color:                             #060606;
$back_to_top_hover_background_color:                  #ffffff;

//=============================
// Fonts
//=============================
$POPPINS:                                             'Poppins-Regular', sans-serif;
$POPPINS_b:                                           'Poppins-Bold', sans-serif;

$font_primary:                                        $POPPINS;
$font_primary_bold:                                   $POPPINS_b;

$f_size_sm:                                           0.789rem; // 15px
$f_size_df:                                           0.895rem; // 17px
$f_size_lg:                                           1rem;     // 19px

//=============================
// Spacing
//=============================
$space_xl:                                            8.421rem; // 160px
$space_lg:                                            3.158rem; // 60px
$space_md:                                            2.632rem; // 50px
$space_sm:                                            2.368rem; // 45px
$space_xs:                                            1.579rem; // 30px

// For page content
$space_pc_primary:                                    3.158rem; // 60px
$space_pc_secondary:                                  1.579rem; // 30px

//=============================
// Header
//=============================
$header_padding_t:                                    2.263rem;
$header_padding_b:                                    2.263rem;

//=============================
// Main
//=============================
$main_padding_t:                                      $space_xl - $header_padding_b;
$main_padding_b:                                      $space_xl;

//=============================
// Footer
//=============================
$footer_padding_t:                                    $space_lg;
$footer_padding_b:                                    $space_lg;

//=============================
// Wrapper
//=============================
$wrapper_max_w:                                       1700px;

// Left
$wrapper_p_l:                                         2.632rem;
$wrapper_p_l_xxl:                                     2.632rem;
$wrapper_p_l_xl:                                      1.59515rem;
$wrapper_p_l_sm:                                      1.59515rem;

// Right
$wrapper_p_r:                                         2.632rem;
$wrapper_p_r_xxl:                                     2.632rem;
$wrapper_p_r_xl:                                      1.59515rem;
$wrapper_p_r_sm:                                      1.59515rem;

//=============================
// Transition s
//=============================
$transition-s:                                        0.3s;

//=============================
// Components
//=============================
$border_radius:                                       0.25rem;
$border_radius_sm:                                    0.3rem;
$border_radius_lg:                                    0.2rem;

$caret_vertical_align:                                middle;
$caret_spacing:                                       0.263rem;

$disabled_opacity:                                    0.5;

//=============================
// Typography
//=============================
$font_family_base:                                    $font_primary;

$h1_font_size:                                        2.474rem;
$h2_font_size:                                        2.053rem;
$h3_font_size:                                        1.737rem;
$h4_font_size:                                        1.421rem;
$h5_font_size:                                        1.211rem;
$h6_font_size:                                        1rem;

$line_height_base:                                    1;
$body_color:                                          $body_text_primary_color;

$hr_border_color:                                     $body_border_color;

// Font margin bottom
$h1_font_mb:                                          0.891rem;
$h2_font_mb:                                          0.708rem;
$h3_font_mb:                                          0.608rem;
$h4_font_mb:                                          0.519rem;
$h5_font_mb:                                          0.466rem;
$h6_font_mb:                                          0.43999999999999995rem;
$p_font_mb:                                           0.57rem;
$li_font_mb:                                          0.57rem;

//=============================
// Links
//=============================
$link_color:                                          $body_links_color;
$link_hover_color:                                    $body_links_hover_color;
$link_hover_decoration:                               none;

//=============================
// Buttons
//=============================
$btn_font_family:                                     $font_primary_bold;
$btn_font_weight:                                     700;

$btn_padding_y:                                       1.132rem;
$btn_padding_x:                                       1.132rem;
$btn_padding_y_sm:                                    0.527rem;
$btn_padding_x_sm:                                    0.527rem;
$btn_padding_y_lg:                                    1.579rem;
$btn_padding_x_lg:                                    1.579rem;

$btn_font_size:                                       $f_size_df;
$btn_font_size_sm:                                    $f_size_sm;
$btn_font_size_lg:                                    $f_size_lg;

$btn_line_height:                                     1.23;
$btn_line_height_sm:                                  1.23;
$btn_line_height_lg:                                  1.23;

$btn_border_radius:                                   0;
$btn_border_radius_sm:                                0;
$btn_border_radius_lg:                                0;

$btn_focus_width:                                     0;

$btn_transition:                                      color $transition-s / 2 ease-in-out, background-color $transition-s / 2 ease-in-out, border-color $transition-s / 2 ease-in-out, box-shadow $transition-s / 2 ease-in-out, opacity $transition-s / 2 ease-in-out;
$btn_transition_opacity:                              opacity $transition-s / 2 ease-in-out;

//=============================
// Badge
//=============================
$badge_border_radius:                                 0;

//=============================
// Modals
//=============================
$modal_footer_margin_between:                         0;

$modal_inner_padding:                                 1.526rem 0;
$modal_dialog_margin:                                 1.526rem;

$modal_content_bg:                    	              $modal_background_content_color;
$modal_content_border_color:                          transparent;
$modal_content_border_width:                          0;
$modal_content_border_radius:                         0;

$modal_backdrop_bg:                  	              $modal_background_color;
$modal_backdrop_opacity:              	              1;
$modal_header_border_color:                           transparent;
$modal_header_border_width:                           0;
$modal_footer_border_color:                           transparent;
$modal_footer_border_width:                           0;

$modal_header_padding_y:                              $header_padding_t;
$modal_header_padding_x:                              0;
$modal_header_padding:                                $header_padding_t 0 $header_padding_b 0;

//=============================
// Alert
//=============================
$alert_padding_y:                                     1rem;
$alert_padding_x:                                     1rem;
$alert_margin_bottom:                                 $space_xs;
$alert_border_radius:                                 0;

//=============================
// List group
//=============================
$list_group_bg:                                       transparent;
$list_group_border_color:                             $body_border_color;
$list_group_border_radius:                            0;

$list_group_item_padding-y:                           $space_xs;
$list_group_item_padding-x:                           $space_xs;

//=============================
// Figures
//=============================
$figure_caption_font_size:                            $f_size_sm;
$figure_caption_color:                                $body_text_primary_color;

//=============================
// Close
//=============================
$close_color:                                         $body_links_color;

//=============================
// Forms
//=============================
$label_margin_bottom:                                 0;

$input_padding_y:                                     1.251rem / 1.5;
$input_padding_x:                                     1.579rem / 1.5;
$input_padding_y_sm:                                  1.251rem / 2.5;
$input_padding_x_sm:                                  1.579rem / 2.5;
$input_padding_y_lg:                                  1.251rem;
$input_padding_x_lg:                                  1.579rem;
$input_font_size:                                     $f_size_df;
$input_font_size_sm:                                  $f_size_sm;
$input_font_size_lg:                                  $f_size_lg;
$input_line_height:                                   1.31;
$input_line_height_sm:                                1.31;
$input_line_height_lg:                                1.31;

$input_bg:                                            $input_background_secondary_color;

$input_color:                                         $input_text_color;

$input_border_color:                                  $input_border_secondary_color;
$input_border_radius:                                 0;
$input_border_radius_lg:                              0;
$input_border_radius_sm:                              0;

$input_focus_border_color:                            $input_border_focus_color;
$input_focus_box_shadow:                              inherit;

$input_height:                                        auto;
$input_height_sm:                                     auto;
$input_height_lg:                                     auto;

$form_check_input_gutter:                             1.526rem;
$form_check_input_margin_y:                           0;
$form_check_input_margin_x:                           0.526rem;

$form_group_margin_bottom:                            $space_lg;

$input_group_addon_bg:                                $input_border_color;

$custom_control_gutter:                               $f_size_lg;

$custom_control_indicator_size:                       $f_size_lg;
$custom_control_indicator_checked_bg:                 $body_main_element_primary_color;
$custom_control_indicator_border_color:               $body_text_primary_color;

$custom_checkbox_indicator_border_radius:             0;

$custom_select_border_radius:                         0;
$custom_select_focus_box_shadow:                      inherit;

//=============================
// Buttons + Forms
//=============================
$input_btn_focus_box_shadow:                          inherit;

//=============================
// Navs
//=============================
$nav_link_disabled_color:                             rgba($body_links_color, $disabled_opacity);

$nav_tabs_border_radius:                              0;
$nav_tabs_link_active_color:                          $body_links_hover_color;

$nav_pills_border_radius:                             0;

//=============================
// Dropdowns
//=============================
$dropdown_min_width:                                  12.105rem;
$dropdown_padding_y:                                  0;
$dropdown_spacer:                                     0;
$dropdown_font_size:                                  0.789rem;
$dropdown_color:                                      $dropdown_links_color;
$dropdown_border_color:                               transparent;
$dropdown_bg:                                         $dropdown_links_background_color;
$dropdown_border_radius:                              0;
$dropdown_border_width:                               0;
$dropdown_divider_bg:                                 $body_border_color;
$dropdown_divider_margin_y:                           0;

$dropdown_link_color:                                 $dropdown_links_color;
$dropdown_link_hover_color:                           $dropdown_links_hover_color;
$dropdown_link_hover_bg:                              $dropdown_links_hover_background_color;

$dropdown_link_active_color:                          $dropdown_links_active_color;
$dropdown_link_active_bg:                             $dropdown_links_active_background_color;
$dropdown_link_disabled_color:                        rgba($dropdown_links_color, $disabled_opacity);

$dropdown_item_padding_y:                             0.789rem;
$dropdown_item_padding_x:                             1.053rem;

//=============================
// Pagination
//=============================
$pagination_padding_x:                                0.953rem;
$pagination_padding_y:                                0.632rem;

$pagination_bg:                                       $body_background_color;
$pagination_border_width:                             0;
$pagination_border_color:                             transparent;

$pagination_hover_border_color:                       transparent;

$pagination_disabled_color:                           rgba($body_links_color, $disabled_opacity);
$pagination_disabled_bg:                              $body_background_color;

//=============================
// Card
//=============================
$card_spacer_y:                                       $space_xs;
$card_spacer_x:                                       $space_xs;
$card_cap_bg:                                         $card_background_color;
$card_bg:                                             $card_background_color;
$card_border_color:                                   $card_border_color;
$card_border_radius:                                  0;
$card_inner_border_radius:                            0;
$card_img_overlay_padding:                            $space_xs;

//=============================
// Table
//=============================
$table_caption_color:                                 $body_text_primary_color;
