//=================================================
// Header
//=================================================

$header_item_space: 2.632rem;

.site-header {
	position: relative;
	background-color: $header_background_color;
	color: $header_color;

	.wrapper {
		padding-top: $header_padding_t;
		padding-bottom: $header_padding_b;
	}

	.header-content {
		.header-left {
			margin-right: $header_item_space;
		}

		.header-right {
			.d-flex {
				> * {
					margin-right: $header_item_space;

					&:last-child {
						margin-right: 0;
					}

					@media #{$media_xxl} {
						margin-right: $header_item_space / 2;
					}
				}

				.search-toggle, .menu-toggle {
					margin-right: 0;

					@media #{$media_md} {
						margin-right: $header_item_space / 4;

						&:last-child {
							margin-right: 0;
						}
					}
				}

				.menu-toggle {
					display: none;
				}

				@media #{$media_md} {
					> .menu {
						display: none;
					}

					.menu-toggle {
						&:not(.adv-light):not(.adv-dark) {
							display: block;
						}
					}
				}
			}
		}
	}
}

.modal-header {
	.header-content {
		.header-left {
			margin-right: $header_item_space;
		}

		.header-right {
			.d-flex {
				> * {
					margin-right: $header_item_space;

					&:last-child {
						margin-right: 0;
					}

					@media #{$media_xxl} {
						margin-right: $header_item_space / 2;
					}
				}

				.search-toggle, .menu-toggle {
					margin-right: $header_item_space / 2;

					&:last-child {
						margin-right: 0;
					}

					@media #{$media_xxl} {
						margin-right: $header_item_space / 4;
					}
				}
			}
		}
	}
}

.header-absolute-true {
	header {
		&.site-header {
			background-color: $header_absolute_background_color;
			// position: absolute;
			width: 100%;
			z-index: 1040;
			min-width: 320px;
			// top: 0px;

			// Color
			color: $header_absolute_color;

			h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cit, code,
			del, dfn, em, ins, q, samp, small, strong, sub, sup, b, i, hr, dl, dt, dd,
			ol, ul, li, fieldset, legend, label, span, caption, figcaption, details, hgroup, summary, mark, audio, video {
				&:not(.btn):not(.dropdown-item):not(.dropdown-custom-icon) {
					color: inherit;
				}
			}

			a {
				&:not(.transform-scale-h):not(.btn):not(.dropdown-item) {
					&:hover {
						opacity: 0.5;
					}
				}
			}
		}
	}

	&.header-fixed {
		header {
			&.site-header {
				background-color: $header_fixed_background_color;

				@media #{$media_md} {
					background-color: transparent;
				}
			}
		}
	}

	// &:not(.header-fixed) {
	// 	header {
	// 		// Header menu color
	// 		&#header {
	// 			nav {
	// 				&.menu-primary {
	// 					ul.nav {
	// 						li.nav-item {
	// 							> a {
	// 								color: inherit;
	// 							}

	// 							> .dropdown-custom-icon {
	// 								.dropdown-icon {
	// 									color: inherit;
	// 								}
	// 							}

	// 							&:hover {
	// 								> a {
	// 									color: inherit;
	// 									opacity: 0.5;
	// 								}

	// 								> .dropdown-custom-icon {
	// 									.dropdown-icon {
	// 										color: inherit;
	// 										opacity: 0.5;
	// 									}
	// 								}
	// 							}

	// 							&.current-nav-item, &.active, &.show {
	// 								> a {
	// 									color: inherit;
	// 								}

	// 								> .dropdown-custom-icon {
	// 									.dropdown-icon {
	// 										color: inherit;
	// 									}
	// 								}
	// 							}

	// 							// Dropdown
	// 							> .dropdown-custom-icon {
	// 								&:hover {
	// 									color: inherit;

	// 									.dropdown-icon {
	// 										color: inherit;
	// 									}
	// 								}
	// 							}
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}

.header-fixed-true {
	&.header-fixed {
		header {
			position: fixed;
			z-index: 1040;

			@media #{"(max-width: 600px)"} {
				top: 0;
			}

			.adv-light {
				display: none;

				&.menu-toggle {
					@media #{$media_md} {
						display: none !important;
					}
				}

				&.logo {
					@media #{$media_md} {
						display: none;
					}
				}
			}

			.adv-dark {
				display: block;

				&.menu-toggle {
					@media #{$media_md} {
						display: block !important;
					}
				}

				&.logo {
					@media #{$media_md} {
						display: none;
					}
				}
			}

			.menu {
				margin-right: 0 !important;
			}
		}
	}
}

.header-absolute-true:not(.header-fixed) {
	header {
		.adv-light {
			&.menu-toggle {
				@media #{$media_md} {
					display: block !important;
				}
			}
		}

		.adv-dark {
			display: none;

			&.menu-toggle {
				@media #{$media_md} {
					display: none !important;
				}
			}
		}
	}
}

body {
	&:not([class*='page-header-absolute-and-no-title-and-content-full-width']):not([class*='page-header-fixed-and-no-title-and-content-full-width']) {
		&.header-absolute-true {
			main {
				padding-top: 0;

				> .content {
					> .clearfix {
						padding-top: 7.895rem;

						> section:first-child,
						> div:first-child,
						> header:first-child,
						> footer:first-child,
						> main:first-child,
						> article:first-child,
						> aside:first-child,
						> nav:first-child {
							> section:first-child,
							> div:first-child,
							> header:first-child,
							> footer:first-child,
							> main:first-child,
							> article:first-child,
							> aside:first-child,
							> nav:first-child {
								padding-top: 7.895rem;
								margin-top: -7.895rem;
							}
						}
					}
				}
			}
		}
	}
}

[class*='page-header-absolute-and-no-title-and-content-full-width'],
[class*='page-header-fixed-and-no-title-and-content-full-width'] {
	&.header-absolute-true {
		main {
			padding-top: 0;

			> .content {
				> .clearfix {
					padding-top: 7.895rem;

					[class*='section-wrap'] {
						> section:first-child,
						> div:first-child,
						> header:first-child,
						> footer:first-child,
						> main:first-child,
						> article:first-child,
						> aside:first-child,
						> nav:first-child {
							padding-top: 7.895rem;
							margin-top: -7.895rem;
						}
					}
				}
			}
		}
	}
}
